h1,
h2,
h3 {
  line-height: 1.2;
}

body {
  font: 18px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-display: optional;
}

@media (prefers-color-scheme: light) {
  // Minima-light modificatitons
  :root {
    --minima-text-color: #444;
    --minima-background-color: #f8f8f8;
  }

  // GitHub Light
  .highlight .tok-hll { background-color: #ffffcc }
  .highlight .tok-c { color: #999988; font-style: italic } /* Comment */
  .highlight .tok-err { color: #a61717; background-color: #e3d2d2 } /* Error */
  .highlight .tok-k { color: #000000; font-weight: bold } /* Keyword */
  .highlight .tok-o { color: #000000; font-weight: bold } /* Operator */
  .highlight .tok-cm { color: #999988; font-style: italic } /* Comment.Multiline */
  .highlight .tok-cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
  .highlight .tok-c1 { color: #999988; font-style: italic } /* Comment.Single */
  .highlight .tok-cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
  .highlight .tok-gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
  .highlight .tok-ge { color: #000000; font-style: italic } /* Generic.Emph */
  .highlight .tok-gr { color: #aa0000 } /* Generic.Error */
  .highlight .tok-gh { color: #999999 } /* Generic.Heading */
  .highlight .tok-gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
  .highlight .tok-go { color: #888888 } /* Generic.Output */
  .highlight .tok-gp { color: #555555 } /* Generic.Prompt */
  .highlight .tok-gs { font-weight: bold } /* Generic.Strong */
  .highlight .tok-gu { color: #aaaaaa } /* Generic.Subheading */
  .highlight .tok-gt { color: #aa0000 } /* Generic.Traceback */
  .highlight .tok-kc { color: #000000; font-weight: bold } /* Keyword.Constant */
  .highlight .tok-kd { color: #000000; font-weight: bold } /* Keyword.Declaration */
  .highlight .tok-kn { color: #000000; font-weight: bold } /* Keyword.Namespace */
  .highlight .tok-kp { color: #000000; font-weight: bold } /* Keyword.Pseudo */
  .highlight .tok-kr { color: #000000; font-weight: bold } /* Keyword.Reserved */
  .highlight .tok-kt { color: #445588; font-weight: bold } /* Keyword.Type */
  .highlight .tok-m { color: #009999 } /* Literal.Number */
  .highlight .tok-s { color: #d01040 } /* Literal.String */
  .highlight .tok-na { color: #008080 } /* Name.Attribute */
  .highlight .tok-nb { color: #0086B3 } /* Name.Builtin */
  .highlight .tok-nc { color: #445588; font-weight: bold } /* Name.Class */
  .highlight .tok-no { color: #008080 } /* Name.Constant */
  .highlight .tok-nd { color: #3c5d5d; font-weight: bold } /* Name.Decorator */
  .highlight .tok-ni { color: #800080 } /* Name.Entity */
  .highlight .tok-ne { color: #990000; font-weight: bold } /* Name.Exception */
  .highlight .tok-nf { color: #990000; font-weight: bold } /* Name.Function */
  .highlight .tok-nl { color: #990000; font-weight: bold } /* Name.Label */
  .highlight .tok-nn { color: #555555 } /* Name.Namespace */
  .highlight .tok-nt { color: #000080 } /* Name.Tag */
  .highlight .tok-nv { color: #008080 } /* Name.Variable */
  .highlight .tok-ow { color: #000000; font-weight: bold } /* Operator.Word */
  .highlight .tok-w { color: #bbbbbb } /* Text.Whitespace */
  .highlight .tok-mf { color: #009999 } /* Literal.Number.Float */
  .highlight .tok-mh { color: #009999 } /* Literal.Number.Hex */
  .highlight .tok-mi { color: #009999 } /* Literal.Number.Integer */
  .highlight .tok-mo { color: #009999 } /* Literal.Number.Oct */
  .highlight .tok-sb { color: #d01040 } /* Literal.String.Backtick */
  .highlight .tok-sc { color: #d01040 } /* Literal.String.Char */
  .highlight .tok-sd { color: #d01040 } /* Literal.String.Doc */
  .highlight .tok-s2 { color: #d01040 } /* Literal.String.Double */
  .highlight .tok-se { color: #d01040 } /* Literal.String.Escape */
  .highlight .tok-sh { color: #d01040 } /* Literal.String.Heredoc */
  .highlight .tok-si { color: #d01040 } /* Literal.String.Interpol */
  .highlight .tok-sx { color: #d01040 } /* Literal.String.Other */
  .highlight .tok-sr { color: #009926 } /* Literal.String.Regex */
  .highlight .tok-s1 { color: #d01040 } /* Literal.String.Single */
  .highlight .tok-ss { color: #990073 } /* Literal.String.Symbol */
  .highlight .tok-bp { color: #999999 } /* Name.Builtin.Pseudo */
  .highlight .tok-vc { color: #008080 } /* Name.Variable.Class */
  .highlight .tok-vg { color: #008080 } /* Name.Variable.Global */
  .highlight .tok-vi { color: #008080 } /* Name.Variable.Instance */
  .highlight .tok-il { color: #009999 } /* Literal.Number.Integer.Long */
}

@media (prefers-color-scheme: dark) {
  // Minima-dark Dracula Adaptation
  :root {
    --minima-brand-color: #abb2bf;
    --minima-brand-color-light: #f8f8f2;
    --minima-brand-color-dark: #191a21;
    --minima-site-title-color: #abb2bf;
    --minima-text-color: #f8f8f2;
    --minima-background-color: #21222c;
    --minima-code-background-color: #191a21;
    --minima-link-base-color: #58a6ff;
    --minima-link-visited-color: #8e96f0;
    --minima-link-hover-color: #8be9fd;
    --minima-border-color-01: #404040;
    --minima-border-color-02: #a6a6a6;
    --minima-border-color-03: #999999;
    --minima-table-text-color: #bbbbbb;
    --minima-table-zebra-color: #282a36;
    --minima-table-header-bg-color: --minima-background-color;
    --minima-table-header-border: --minima-brand-color-dark;
    --minima-table-border-color: #404040;
  }

  // Dracula
  pre {
    line-height: 125%;
  }

  td.linenos .normal {
    color: inherit;
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
  }

  span.linenos {
    color: inherit;
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
  }

  td.linenos .special {
    color: #282a36;
    background-color: #282a36;
    padding-left: 5px;
    padding-right: 5px;
  }

  span.linenos.special {
    color: #282a36;
    background-color: #282a36;
    padding-left: 5px;
    padding-right: 5px;
  }

  .tok-hll { background-color: #282a36; }
  .tok-c   { color: #6272a4; font-style: italic; } /* Comment */
  .tok-err { border: 1px solid #ff6e6e; }          /* Error */
  .tok-k   { color: #ff79c6; font-weight: bold; }  /* Keyword */
  .tok-o   { color: #ff79c6; }                     /* Operator */
  .tok-ch  { color: #6272a4; font-style: italic; } /* Comment.Hashbang */
  .tok-cm  { color: #6272a4; font-style: italic; } /* Comment.Multiline */
  .tok-cp  { color: #6272a4; }                     /* Comment.Preproc */
  .tok-cpf { color: #6272a4; font-style: italic; } /* Comment.PreprocFile */
  .tok-c1  { color: #6272a4; font-style: italic; } /* Comment.Single */
  .tok-cs  { color: #6272a4; font-style: italic; } /* Comment.Special */
  .tok-gd  { color: #3b4048; }                     /* Generic.Deleted */
  .tok-ge  { font-style: italic; }                 /* Generic.Emph */
  .tok-gr  { color: #ff6e6e; }                     /* Generic.Error */
  .tok-gh  { color: #bd93f9; font-weight: bold; }  /* Generic.Heading */
  .tok-gi  { color: #50fa7b; }                     /* Generic.Inserted */
  .tok-go  { color: #6272a4; }                     /* Generic.Output */
  .tok-gp  { color: #50fa7b; font-weight: bold; }  /* Generic.Prompt */
  .tok-gs  { font-weight: bold; }                  /* Generic.Strong */
  .tok-gu  { color: #bd93f9; font-weight: bold; }  /* Generic.Subheading */
  .tok-gt  { color: #bd93f9; }                     /* Generic.Traceback */
  .tok-kc  { color: #bd93f9; font-weight: bold; }  /* Keyword.Constant */
  .tok-kd  { color: #ff79c6; font-weight: bold; }  /* Keyword.Declaration */
  .tok-kn  { color: #ffb86c; font-weight: bold; }  /* Keyword.Namespace */
  .tok-kp  { color: #bd93f9; }                     /* Keyword.Pseudo */
  .tok-kr  { color: #ff79c6; font-weight: bold; }  /* Keyword.Reserved */
  .tok-kt  { color: #a4ffff; }                     /* Keyword.Type */
  .tok-m   { color: #bd93f9; }                     /* Literal.Number */
  .tok-s   { color: #f1fa8c; }                     /* Literal.String */
  .tok-na  { color: #8be9fd; }                     /* Name.Attribute */
  .tok-nb  { color: #f1fa8c; }                     /* Name.Builtin */
  .tok-nc  { color: #8be9fd; font-weight: bold; }  /* Name.Class */
  .tok-no  { color: #bd93f9; }                     /* Name.Constant */
  .tok-nd  { color: #8be9fd; }                     /* Name.Decorator */
  .tok-ni  { color: #ffb86c; font-weight: bold; }  /* Name.Entity */
  .tok-ne  { color: #bd93f9; font-weight: bold; }  /* Name.Exception */
  .tok-nf  { color: #50fa7b; }                     /* Name.Function */
  .tok-nl  { color: #8be9fd; }                     /* Name.Label */
  .tok-nn  { color: #ffb86c; font-weight: bold; }  /* Name.Namespace */
  .tok-nt  { color: #8be9fd; font-weight: bold; }  /* Name.Tag */
  .tok-nv  { color: #f8f8f2; }                     /* Name.Variable */
  .tok-ow  { color: #ff79c6; font-weight: bold; }  /* Operator.Word */
  .tok-w   { color: #ff5555; }                     /* Text.Whitespace */
  .tok-mb  { color: #bd93f9; }                     /* Literal.Number.Bin */
  .tok-mf  { color: #50fa7b; }                     /* Literal.Number.Float */
  .tok-mh  { color: #bd93f9; }                     /* Literal.Number.Hex */
  .tok-mi  { color: #bd93f9; }                     /* Literal.Number.Integer */
  .tok-mo  { color: #bd93f9; }                     /* Literal.Number.Oct */
  .tok-sa  { color: #f1fa8c; }                     /* Literal.String.Affix */
  .tok-sb  { color: #f1fa8c; }                     /* Literal.String.Backtick */
  .tok-sc  { color: #f1fa8c; }                     /* Literal.String.Char */
  .tok-dl  { color: #f1fa8c; }                     /* Literal.String.Delimiter */
  .tok-sd  { color: #f1fa8c; font-style: italic; } /* Literal.String.Doc */
  .tok-s2  { color: #f1fa8c; }                     /* Literal.String.Double */
  .tok-se  { color: #8be9fd; font-weight: bold; }  /* Literal.String.Escape */
  .tok-sh  { color: #f1fa8c; }                     /* Literal.String.Heredoc */
  .tok-si  { color: #8be9fd; font-weight: bold; }  /* Literal.String.Interpol */
  .tok-sx  { color: #f1fa8c; }                     /* Literal.String.Other */
  .tok-sr  { color: #ff5555; }                     /* Literal.String.Regex */
  .tok-s1  { color: #f1fa8c; }                     /* Literal.String.Single */
  .tok-ss  { color: #bd93f9; }                     /* Literal.String.Symbol */
  .tok-bp  { color: #ff79c6; }                     /* Name.Builtin.Pseudo */
  .tok-fm  { color: #50fa7b; }                     /* Name.Function.Magic */
  .tok-vc  { color: #8be9fd; }                     /* Name.Variable.Class */
  .tok-vg  { color: #f8f8f2; }                     /* Name.Variable.Global */
  .tok-vi  { color: #8be9fd; }                     /* Name.Variable.Instance */
  .tok-vm  { color: #f8f8f2; }                     /* Name.Variable.Magic */
  .tok-il  { color: #bd93f9; }                     /* Literal.Number.Integer.Long */
}
